import React, { useEffect, useRef, useCallback, useState } from 'react';
import logo from '../resource/image/clabil-logo.png';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import menuImage from '../resource/image/menuImage.png';
function Header({}) {
    const [hoverCompany, setHoverCompany] = useState(false);
    const [hoverSolution, setHoverSolution] = useState(false);
    const [hoverNews, setHoverNews] = useState(false);
    const [hoverRequest, setHoverRequest] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [menuVisible, setMenuVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
            if (window.innerWidth > 950) {
                setMenuVisible(false);
            }
        });
    }, []);
    return (
        <div
            style={{
                backgroundColor: 'white',
                width: '100%',
                height: 90,
                borderBottom: '1px solid #ddd',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
            }}
        >
            {menuVisible && (
                <div
                    style={{
                        position: 'absolute',
                        top: 10,
                        right: 0,
                        backgroundColor: 'white',
                        width: (width / 1920) * 500,
                        // height: 200,
                        border: '1px solid #ddd',
                        zIndex: 10,
                        flexDirection: 'column',
                        fontSize: 20,
                        alignItems: 'center',
                        padding: 10,
                        boxShadow: '0px 5px 10px  #777',
                        // borderBottomLeftRadius: 10,
                        // borderBottomRightRadius: 10,
                    }}
                >
                    {/* <div
                        style={{
                            borderBottom: '1px solid #fff',
                            textAlign: 'center',
                            cursor: 'pointer',
                            marginBottom: 5,
                        }}
                    >
                        회사소개
                    </div>
                    <div
                        style={{
                            borderBottom: '1px solid #fff',
                            textAlign: 'center',
                            cursor: 'pointer',
                            marginBottom: 5,
                        }}
                    >
                        솔루션
                    </div> */}
                    <div
                        onClick={() => {
                            navigate('/contact');
                        }}
                        style={{ borderBottom: '1px solid #fff', textAlign: 'center', cursor: 'pointer' }}
                    >
                        문의하기
                    </div>
                </div>
            )}
            <div
                style={{
                    backgroundColor: 'white',
                    width: width > 1200 ? '23%' : '50%',
                    height: '100%',
                    // justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    // border: '1px solid #ddd',
                }}
            >
                <img
                    onClick={() => {
                        // console.log('onClick');
                        navigate('/');
                    }}
                    src={logo}
                    width={200}
                    height={60}
                    style={{ padding: 0, marginTop: 0, cursor: 'pointer' }}
                />
            </div>
            {width > 950 ? (
                <div
                    style={{
                        backgroundColor: 'white',
                        width: width > 1200 ? '40%' : '40%',
                        height: '100%',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        display: 'flex',
                        // border: '1px solid #ddd',
                    }}
                >
                    {/* <button
                        onClick={() => {
                            // navigate('/company');
                        }}
                        onMouseOver={() => {
                            setHoverCompany(true);
                        }}
                        onMouseOut={() => {
                            setHoverCompany(false);
                        }}
                        style={{
                            backgroundColor: 'white',
                            border: '0px',
                            cursor: 'pointer',
                            width: '25%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '20px',
                            fontWeight: hoverCompany ? 'bold' : 'normal',
                            justifyContent: 'flex-end',
                        }}
                    >
                        회사소개
                    </button>
                    <button
                        onClick={() => {
                            // console.log('onClick');
                        }}
                        onMouseOver={() => {
                            setHoverSolution(true);
                        }}
                        onMouseOut={() => {
                            setHoverSolution(false);
                        }}
                        style={{
                            backgroundColor: 'white',
                            border: '0px',
                            cursor: 'pointer',
                            width: '25%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '20px',
                            fontWeight: hoverSolution ? 'bold' : 'normal',
                            justifyContent: 'flex-end',
                        }}
                    >
                        솔루션
                    </button> */}
                    {/* <button
                    onClick={() => {
                        console.log('onClick');
                    }}
                    onMouseOver={() => {
                        setHoverNews(true);
                    }}
                    onMouseOut={() => {
                        setHoverNews(false);
                    }}
                    style={{
                        backgroundColor: 'white',
                        border: '0px',
                        cursor: 'pointer',
                        width: '25%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '20px',
                        fontWeight: hoverNews ? 'bold' : 'normal',
                        justifyContent: 'flex-end',
                    }}
                >
                    뉴스
                </button> */}
                    <button
                        onClick={() => {
                            // console.log('onClick');
                            navigate('/contact');
                        }}
                        onMouseOver={() => {
                            setHoverRequest(true);
                        }}
                        onMouseOut={() => {
                            setHoverRequest(false);
                        }}
                        style={{
                            backgroundColor: 'white',
                            border: '0px',
                            cursor: 'pointer',
                            width: '25%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '20px',
                            fontWeight: hoverRequest ? 'bold' : 'normal',
                            justifyContent: 'flex-end',
                        }}
                    >
                        문의하기
                    </button>
                </div>
            ) : (
                <div
                    style={{
                        backgroundColor: 'white',
                        width: width > 1200 ? '40%' : '45%',
                        height: '100%',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        display: 'flex',
                        // border: '1px solid #ddd',
                    }}
                >
                    <img
                        onClick={() => {
                            // console.log('setMenuVisible');
                            setMenuVisible(true);
                        }}
                        src={menuImage}
                        width={60}
                        height={60}
                        style={{ padding: 0, marginTop: 0, cursor: 'pointer' }}
                    />
                </div>
            )}
        </div>
    );
}

export default Header;
