import React, { useEffect, useRef, useCallback, useState } from 'react';
import Header from '../View/Header';
import videoDemo from '../resource/clabilTest.mp4';
import videoAni from '../resource/clabilAni.mp4';
import autoImge from '../resource/image/autoImge.png';
import smartReteil from '../resource/image/smartReteil.png';
import main from '../resource/image/main.png';
import cloudService from '../resource/image/cloudService.png';
import businessSolution from '../resource/image/businessSolution.png';
import b2bSolution from '../resource/image/b2bSolution.png';
import b2cSolution from '../resource/image/b2cSolution.png';
import roverPicture from '../resource/image/roverPicture.png';
import wheelControl from '../resource/image/wheelControl.png';
import roverModel from '../resource/image/roverModel.png';
import autoCase2 from '../resource/image/autoCase2.jpg';
import autoCase3 from '../resource/image/autoCase3.jpg';
import autoCase4 from '../resource/image/autoCase4.jpg';
import arrowLeft from '../resource/image/arrowLeft.png';
import arrowRight from '../resource/image/arrowRight.png';
import steerMode from '../resource/image/steerMode.png';
import steerMode1 from '../resource/image/steerMode1.png';
import steerMode2 from '../resource/image/steerMode2.png';
import cloudManageService from '../resource/image/cloudManageService.png';
import productOverview from '../resource/image/productOverview.png';
import youtube from '../resource/image/youtube.png';
import { Container, Link, Button, lightColors, darkColors } from 'react-floating-action-button';
import { FloatButton } from 'antd';
import { QuestionCircleOutlined, SyncOutlined, YoutubeOutlined } from '@ant-design/icons';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import Footer from '../View/Footer';

// import { Container as NaverMap, Marker, useNavermaps } from 'react-naver-maps';
// import MapNavigation from './MapNavigation';
// import GlobalCSS from '../../Style/GlobalCSS';
// import NaverMapContainer from './NaverMapContainer';

import ReactPlayer from 'react-player';
import { cloneDeep } from 'lodash';

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function Main() {
    const [hoverCompany, setHoverCompany] = useState(false);
    const [hoverSolution, setHoverSolution] = useState(false);
    const [hoverNews, setHoverNews] = useState(false);
    const [hoverRequest, setHoverRequest] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [mainTitleFontSize, setMainTitleFontSize] = useState(70);
    const [slide, setSlide] = useState(true);
    const [images, setImages] = useState([
        { image: autoCase4, name: '스마트 팩토리' },
        { image: autoCase2, name: '순찰' },
        { image: autoCase3, name: '교육' },
    ]);
    const navigate = useNavigate();

    const [videoUrl, setVideoUrl] = useState([
        'https://www.youtube.com/watch?v=fdD3ZIgnpEc',
        'https://www.youtube.com/watch?v=8FGDATWj-Pc',
        // 'animation',
        'https://www.youtube.com/watch?v=o7xzMxStkLg',
        'https://www.youtube.com/watch?v=O8Kks7KX4Hc',
        'https://www.youtube.com/watch?v=N6KARYNyVk0',
    ]);
    const [videoPage, setVideoPage] = useState(1);
    const [menuVisible, setMenuVisible] = useState(false);

    // const images = [autoImge, smartReteil];
    // const navermaps = useNavermaps();
    const { naver } = window;

    const dummys = [
        {
            headerHilight: '자율주행',
            headerNormal: ' 로봇 베이스 플랫폼',
            content:
                '로봇 베이스 플랫폼은 R&D 및 모든 학술 어플리케이션에 이상적인 솔루션입니다. 견고하고 내구성이 뛰어나며, 노면과의 충격을 감소 시키는 서스펜션 구조를 가지고 있습니다. 또한, 상부 구조 별도로 장착하여 다양한 분야에서 응용 가능합니다.',
        },
        {
            headerHilight: '스마트 리테일',
            headerNormal: ' 로봇',
            content:
                '이 자율 로봇은 모든 공공장소의 최종 고객에게 음료 및 소형 상품을 직접 판매 할 수 있습니다. 고객은 도시 공원, 공항 터미널, 쇼핑몰과 같은 사람들이 집중된 공간에서 운영할 수 있습니다. 이 로봇은 언제, 어디서든 고객이 있는 곳으로 갈 것입니다.',
        },
    ];

    useEffect(() => {
        // console.log('window', window.innerWidth, window.innerHeight);
        // const mapDiv = document.getElementById('map');
        // const map = new window.naver.maps.Map(mapDiv);

        setfontSize();
        window.addEventListener('resize', () => {
            console.log('window', window.innerWidth, window.innerHeight);
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
            setfontSize();
        });
    }, []);

    function setfontSize() {
        if (window.innerWidth > 990) {
            setMainTitleFontSize(70);
        } else if (window.innerWidth > 750) {
            setMainTitleFontSize(50);
        } else {
            setMainTitleFontSize(30);
        }
    }

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Header setMenuVisible={setMenuVisible} />
            {/* {menuVisible && <div style={{ position: 'absolute', top: 70, right: 0 }}>ddd</div>} */}

            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    // backgroundColor: 'rgba(0,0,0,0.7)',
                    justifyContent: 'center',
                }}
            >
                {/* <video muted autoPlay loop style={{ marginTop: 0, width: '100%', height: '100%', display: 'flex' }}>
                    <source src={videoDemo} type="video/mp4" />
                </video> */}

                <video
                    muted
                    // controls
                    autoPlay
                    loop
                    style={{ marginTop: 0, width: '100%', height: '100%', display: 'flex' }}
                >
                    <source src={videoAni} type="video/mp4" />
                </video>
                {/* <img src={main} width="100%" height={((width * 0.68) / 2000) * 1274} /> */}
                <div
                    style={{
                        width: '100%',
                        height: (width / 1280) * 720 - 9,
                        display: 'flex',
                        position: 'absolute',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        style={{
                            color: 'white',
                            fontSize: mainTitleFontSize,
                            fontWeight: 'bold',
                        }}
                    >
                        자율주행 기술과 함께하는
                    </div>

                    <div
                        style={{
                            color: 'white',
                            fontSize: mainTitleFontSize,
                            fontWeight: 'bold',
                        }}
                    >
                        일상을 만드는 기업 CLABIL
                    </div>
                </div>
            </div>

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    marginTop: '20vh',
                    justifyContent: 'center',
                    fontSize: width > 1100 ? 60 : 40,
                    fontWeight: 'bold',
                }}
            >
                SOLUTION
            </div>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    marginTop: '5vh',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        width: width > 1300 ? '80%' : '90%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        fontSize: width > 1300 ? 40 : 30,
                        fontWeight: 'bold',
                        borderBottom: '2px solid',
                    }}
                >
                    B2B Solution
                </div>
                <div
                    style={{
                        width: width > 1300 ? '70%' : '90%',
                        display: 'flex',
                        // marginTop: '5vh',
                        justifyContent: width > 1300 ? 'space-between' : 'center',
                        flexDirection: width > 1300 ? 'row' : 'column',
                        alignItems: 'center',
                    }}
                >
                    <img
                        src={productOverview}
                        width={width > 1300 ? (width * 0.65) / 2 : width > 800 ? width * 0.5 : width * 0.6}
                        style={{
                            display: 'flex',
                            // padding: 30,
                        }}
                    />
                    <img
                        src={cloudManageService}
                        width={width > 1300 ? (width * 0.65) / 2 : width > 800 ? width * 0.5 : width * 0.6}
                        style={{
                            display: 'flex',
                            // padding: 30,
                        }}
                    />
                </div>
            </div>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    marginTop: '10vh',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        width: width > 1200 ? '80%' : '90%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        fontSize: width > 1200 ? 40 : 30,
                        fontWeight: 'bold',
                        borderBottom: '2px solid',
                    }}
                >
                    B2C Solution
                </div>
                <div
                    style={{
                        width: width > 1200 ? '65%' : '90%',
                        display: 'flex',
                        marginTop: '6vh',
                        justifyContent: 'center',
                    }}
                >
                    <img
                        src={b2cSolution}
                        width={width > 1200 ? width * 0.45 : width > 800 ? width * 0.5 : width * 0.6}
                        style={{
                            display: 'flex',
                            // padding: 30,
                        }}
                    />
                </div>
            </div>

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    marginTop: '10vh',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        width: width > 1200 ? '75%' : '90%',
                        // marginBottom: '5vh',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // border: '1px solid #ddd',
                    }}
                >
                    <img
                        src={arrowLeft}
                        height={width > 450 ? 40 : 20}
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            let temp = cloneDeep(videoUrl);
                            temp.shift();
                            temp.push(videoUrl[0]);
                            if (videoPage === 1) {
                                setVideoPage(videoUrl.length);
                            } else {
                                setVideoPage(videoPage - 1);
                            }
                            setVideoUrl(temp);
                        }}
                    />

                    {videoUrl.map((item, index) => {
                        return (
                            <div key={`${item}-${index}`}>
                                {(width > 500 ? index > 0 && index < 4 : index === 3) && (
                                    <div
                                        style={{
                                            width: width > 500 ? width * 0.22 : width * 0.5,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginLeft: 10,
                                            marginRight: 10,
                                            flexDirection: 'column',
                                            textAlign: 'center',
                                            fontSize: width > 1200 ? 25 : width > 560 ? 18 : 14,
                                            fontWeight: index === 1 ? 'bold' : 'normal',
                                        }}
                                    >
                                        <ReactPlayer
                                            controls={true}
                                            url={item}
                                            width={width > 500 ? width * 0.22 : width * 0.5}
                                            height={
                                                width > 500 ? ((width * 0.25) / 4) * 2.5 : ((width * 0.5) / 4) * 2.5
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    <img
                        src={arrowRight}
                        height={width > 450 ? 40 : 20}
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            let temp = cloneDeep(videoUrl);
                            temp.pop();
                            temp.unshift(videoUrl[videoUrl.length - 1]);
                            if (videoPage === videoUrl.length) {
                                setVideoPage(1);
                            } else {
                                setVideoPage(videoPage + 1);
                            }
                            setVideoUrl(temp);
                        }}
                    />
                </div>
                <div
                    style={{
                        width: width > 1200 ? '75%' : '100%',
                        marginTop: 10,
                        marginBottom: '5vh',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // border: '1px solid #ddd',
                        fontSize: width > 1200 ? 25 : width > 560 ? 18 : 14,
                        fontWeight: 'bold',
                    }}
                >
                    {videoPage}/{videoUrl.length}
                </div>
            </div>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    marginTop: '5vh',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        width: width > 1350 ? '80%' : '90%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        fontSize: width > 1200 ? 40 : 30,
                        fontWeight: 'bold',
                        borderBottom: '2px solid',
                    }}
                >
                    Driving Platform – CLABIL Rover
                </div>
            </div>
            <div style={{ width: '100%', justifyContent: 'center', marginBottom: '5vh', display: 'flex' }}>
                <div
                    style={{
                        // width: width > 1350 ? '80%' : '90%',
                        width: '80%',
                        display: 'flex',
                        marginTop: '5vh',
                        justifyContent: width > 1350 ? 'space-around' : 'center',
                        alignItems: 'center',
                        // flexDirection: width > 1350 ? 'row' : 'column',
                        flexDirection: 'column',
                        // border: '1px solid #ddd',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '55%',
                            border: '1px solid #ddd',
                        }}
                    >
                        <div style={{ display: 'flex' }}>
                            {/* <div
                                style={{
                                    backgroundColor: '#bbaaff',
                                    width: (width / 1920) * 300,
                                    height: (width / 1920) * 210,
                                    display: 'flex',
                                    position: 'absolute',
                                }}
                            ></div> */}
                            <img
                                src={roverPicture}
                                width={width * 0.45}
                                style={{
                                    display: 'flex',
                                    zIndex: 10,
                                    // padding: 30,
                                    // paddingLeft: 30,
                                    // paddingRight: 30,
                                }}
                            ></img>
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            flexDirection: 'column',
                            // alignItems: 'center',
                            width: '62.5%',
                            // border: '1px solid black',
                            // paddingLeft: width > 1350 ? (width / 1920) * 20 : 0,
                        }}
                    >
                        {/* <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                // marginTop: 20,
                                fontSize: width > 1200 ? 16 : 16,
                                fontWeight: 'normal',
                                marginTop: 20,
                            }}
                        >
                            CLABIL-Rover은 전/후륜 애커먼 스티어링과 Zero-Turn 스티어링 메커니즘이 결합한 드라이빙
                            플랫폼으로서 다양한 실내외 환경에서 주행이 가능합니다. 모듈형 시스템과 CAN 통신 인터페이스를
                            지원하여 사용자가 손쉽게 기능을 확대해 자율 물류, 순찰, 연구 등의 다양한 산업에 활용할 수
                            있습니다.
                        </div> */}
                        {/* <div
                            style={{
                                width: width > 1350 ? width * 0.75 : width * 0.45,
                                display: 'flex',
                                fontSize: width > 1200 ? 27 : 20,
                                fontWeight: 'normal',
                                marginTop: '5vh',
                                // borderBottom: '2px solid',
                            }}
                        >
                            ● Steering Mode
                        </div> */}
                        <div
                            style={{
                                marginTop: '5vh',
                                width: width > 1350 ? '97%' : '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                // flexDirection: width > 1350 ? 'row' : width > 600 ? 'row' : 'column',
                                // flexDirection: 'column',
                                paddingTop: '2vh',
                                // border: '1px solid black',
                            }}
                        >
                            <img
                                src={steerMode}
                                width={width * 0.5}
                                style={{
                                    display: 'flex',
                                    zIndex: 10,
                                }}
                            ></img>
                            {/* <img
                            src={steerMode1}
                            width={width > 1350 ? 1100 * 0.58 : 1100 * 0.3}
                            style={{
                                display: 'flex',
                                zIndex: 10,
                            }}
                        ></img> */}
                            {/* <img
                                src={steerMode2}
                                width={width > 1350 ? 665 * 0.58 : 661 * 0.3}
                                style={{
                                    display: 'flex',
                                    zIndex: 10,
                                    marginLeft: width > 1350 ? 661 * 0.11 : width > 600 ? 661 * 0.07 : 0,
                                    // marginLeft: 661 * 0.04,
                                }}
                            ></img> */}
                        </div>

                        {/* <div
                            style={{
                                width: '71.5%',
                                display: 'flex',
                                fontSize: width > 1200 ? 27 : 20,
                                fontWeight: 'normal',
                                marginTop: '5vh',
                                textAlign: 'left',
                                // border: '1px solid black',
                                // marginLeft: '-1.5%',
                            }}
                        >
                            ● 활용분야 : 물류, 순찰, 연구 등
                        </div> */}
                    </div>
                </div>
            </div>

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    marginTop: '10vh',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        width: width > 1200 ? '80%' : '90%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        fontSize: width > 1200 ? 40 : 30,
                        fontWeight: 'bold',
                        borderBottom: '2px solid',
                    }}
                >
                    R&D History
                </div>
                <div
                    style={{
                        width: width > 1200 ? '65%' : '90%',
                        display: 'flex',
                        marginTop: '5vh',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        style={{
                            width: width > 1200 ? '75%' : '90%',
                            marginBottom: '5vh',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // border: '1px solid #ddd',
                        }}
                    >
                        <img
                            src={arrowLeft}
                            height={width > 500 ? 40 : 20}
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                let temp = [];
                                temp[0] = images[2];
                                temp[1] = images[0];
                                temp[2] = images[1];
                                setImages(temp);
                            }}
                        />
                        {images.map((item, index) => {
                            return (
                                <div style={{ width: width > 500 ? width * 0.23 : width * 0.45 }}>
                                    {(width > 500 ? true : index === 1) && (
                                        <div
                                            ket={`${item.name}-${index}`}
                                            style={{
                                                width: width > 500 ? width * 0.2 : width * 0.45,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                marginLeft: 10,
                                                marginRight: 10,
                                                flexDirection: 'column',
                                                textAlign: 'center',
                                                fontSize: width > 1200 ? 25 : width > 560 ? 18 : width > 500 ? 14 : 12,
                                                fontWeight: index === 1 ? 'bold' : 'normal',
                                            }}
                                        >
                                            <img
                                                src={item.image}
                                                width={width > 500 ? width * 0.2 : width * 0.45}
                                                style={{
                                                    marginBottom: 15,
                                                    filter: index === 1 ? 'blur(0px)' : 'blur(2px)',
                                                }}
                                            />
                                            {item.name}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                        <img
                            src={arrowRight}
                            height={width > 500 ? 40 : 20}
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                let temp = [];
                                temp[0] = images[1];
                                temp[1] = images[2];
                                temp[2] = images[0];
                                setImages(temp);
                            }}
                        />
                    </div>
                </div>
            </div>

            {/* <div style={{ width: '100%', display: 'flex', marginTop: '10vh', justifyContent: 'center' }}>
                <video muted autoPlay loop style={{ marginTop: 0, width: '60%', height: '100%', display: 'flex' }}>
                    <source src={videoAni} type="video/mp4" />
                </video>
            </div> */}

            {/* <div
                style={{
                    width: '100%',
                    display: 'flex',
                    marginTop: '20vh',
                    justifyContent: 'center',
                    fontSize: 60,
                    fontWeight: 'bold',
                }}
            >
                PARTNERS
            </div> */}
            {/* <div
                id="map"
                style={{
                    width: '100%',
                    height: '400px',
                }}
            ></div> */}
            {/* <Container>
                <Button href="/contect" styles={{ backgroundColor: '#ffffff' }}>
                    
                </Button>
                <Link href="/contect">문의</Link>
            </Container> */}
            <FloatButton.Group shape="circle">
                <FloatButton.BackTop visibilityHeight={0} />
                <FloatButton
                    onClick={() => {
                        window.open(
                            'https://www.youtube.com/@user-tf9rk4mp5h/featured',
                            '_blank',
                            'noopener, noreferrer'
                        );
                    }}
                    icon={<YoutubeOutlined style={{ color: '#ff0000' }} />}
                />
                <FloatButton
                    type="primary"
                    onClick={() => {
                        navigate('/contect');
                    }}
                    icon={<QuestionCircleOutlined />}
                />
            </FloatButton.Group>
            <Footer />
        </div>
    );
}

export default Main;
