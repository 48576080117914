import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import Main from './pages/Main.js';
import Company from './pages/Company.js';
import Contact from './pages/Contact.js';
import { NavermapsProvider } from 'react-naver-maps';

function App() {
    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <NavermapsProvider ncpClientId="h62qgs96bo">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" key="./" element={<Main />} />
                        {/* <Route path="/company" key="/company" element={<Main />} /> */}
                        <Route path="/contact" key="/contact" element={<Contact />} />
                        <Route path={'/*'} element={<Main />} />
                    </Routes>
                </BrowserRouter>
            </NavermapsProvider>
        </div>
    );
}

export default App;
