import React, { useEffect, useRef, useCallback, useState } from 'react';
import logo from '../resource/image/clabil-logo.png';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import youtube from '../resource/image/youtube.png';

function Footer({}) {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [menuVisible, setMenuVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
            if (window.innerWidth > 950) {
                setMenuVisible(false);
            }
        });
    }, []);
    return (
        <div
            style={{
                backgroundColor: 'white',
                width: '100%',
                height: 280,
                borderBottom: '1px solid #ddd',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                borderTop: '1px solid #eee',
                backgroundColor: '#eee',
            }}
        >
            <div style={{ width: width > 1200 ? '60%' : '90%', display: 'flex' }}>
                <div style={{ width: '70%', display: 'flex', flexDirection: 'column', color: '#777' }}>
                    <img src={logo} width={150} style={{ marginBottom: 15 }} />
                    <div style={{ flexDirection: width > 610 ? 'row' : 'column', display: 'flex', marginBottom: 5 }}>
                        <div style={{ marginRight: 20, marginBottom: width > 610 ? 0 : 5 }}>CEO 최상천</div>
                        <div>Business Registration 367-81-02254</div>
                    </div>
                    <div style={{ flexDirection: 'row', display: 'flex', marginBottom: 5 }}>
                        서울특별시 송파구 문정동 645 에이치비즈니스파크 A동 1115호
                    </div>
                    <div
                        style={{
                            flexDirection: width > 850 ? 'row' : 'column',
                            display: 'flex',
                            marginBottom: width > 850 ? 5 : 0,
                        }}
                    >
                        <div style={{ marginRight: 20, marginBottom: width > 850 ? 0 : 5 }}>
                            E-mail : sc.choi@clabil.com
                        </div>
                        {width > 850 && <div style={{ color: '#aaa' }}>|</div>}
                        <div
                            style={{
                                marginRight: 20,
                                marginLeft: width > 850 ? 20 : 0,
                                marginBottom: width > 850 ? 0 : 5,
                            }}
                        >
                            Tel : 02-400-1105
                        </div>
                        {width > 850 && <div style={{ color: '#aaa' }}>|</div>}
                        <div
                            style={{
                                marginRight: 20,
                                marginLeft: width > 850 ? 20 : 0,
                                marginBottom: width > 850 ? 0 : 5,
                            }}
                        >
                            Fax : 02-400-1106
                        </div>
                    </div>

                    <div>Copyright © CLABIL Corp. Ltd. All Rights Reserved.</div>
                </div>
                <div
                    onClick={() => {
                        window.open(
                            'https://www.youtube.com/@user-tf9rk4mp5h/featured',
                            '_blank',
                            'noopener, noreferrer'
                        );
                    }}
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        width: '20%',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <img src={youtube} width={50} />
                </div>
            </div>
        </div>
    );
}

export default Footer;
