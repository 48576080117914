import React, { useEffect, useRef, useCallback, useState } from 'react';
import Header from '../View/Header.js';
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from '@emailjs/browser';
import { FloatButton } from 'antd';
import { QuestionCircleOutlined, SyncOutlined, YoutubeOutlined } from '@ant-design/icons';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import Footer from '../View/Footer.js';
function Contact() {
    const navigate = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [checkRobot, setCheckRobot] = useState(false);
    const [acceptPersonalData, setAcceptPersonalData] = useState(false);
    const [content, setContent] = useState();
    const form = useRef();
    const [data, setData] = useState({
        name: '',
        email: '',
        phone: '',
        quantity: '',
        content: '',
    });

    useEffect(() => {
        console.log('content', content);
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        });
    }, []);

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };
    const onSelectFile = (e) => {
        e.preventDefault();
        e.persist();
        //선택한 파일
        const selectedFiles = e.target.files;
        //선택한 파일들을 fileUrlList에 넣어준다.
        // const fileUrlList = [...selectedFiles];

        // 업로드되는 파일에는 url이 있어야 한다. filePath로 보내줄 url이다.
        //획득한 Blob URL Address를 브라우져에서 그대로 호출 시에 이미지는 표시가 되고 ,
        //일반 파일의 경우 다운로드를 할 수 있다.
        // for (let i = 0; i < selectedFiles.length; i++) {
        //     const nowUrl = URL.createObjectURL(selectedFiles[i]);
        //     fileUrlList.push(nowUrl[i]);
        // }

        setSelectedFiles(e.target.files);

        //Array.from() 은 문자열 등 유사 배열(Array-like) 객체나 이터러블한 객체를 배열로 만들어주는 메서드이다.
        // const selectedFileArray = Array.from(selectedFiles);

        //브라우저 상에 보여질 파일 이름
        // const imageArray = selectedFileArray.map((file) => {
        //   return file.name;
        // });

        // // 첨부파일 삭제시
        // setSelectedFiles((previousImages) => previousImages.concat(imageArray));
        // e.target.value = '';
    };
    const submit = (e) => {
        e.preventDefault();
        // let temp = JSON.stringify(form.current, null, 2);
        if (checkRobot) {
            if (acceptPersonalData) {
                console.log('Submit', data);
                emailjs.sendForm('service_clabil', 'mailingService', form.current, 'csDDvPe6wuLDApUGQ').then(
                    (result) => {
                        alert('전송되었습니다.');
                        // closeEmail();
                        window.location.reload();
                    },
                    (error) => {
                        alert('전송을 실패했습니다.');
                    }
                );
            } else {
                alert('개인정보 수집 및 이용에 동의 해주세요');
            }
        } else {
            alert('로봇이 아님을 증명 해주세요.');
        }
        // alert(form.current);
        // setContent(form.current);
    };

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Header />
            <form onSubmit={submit} ref={form} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div
                    style={{
                        width: width > 1200 ? '63%' : '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        // border: '1px solid #ddd',
                        marginTop: '5vh',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ fontSize: 30, fontWeight: 'bold', width: '100%' }}>문의하기</div>

                    <div style={{ width: '90%', flexDirection: 'row', display: 'flex' }}>
                        <div style={{ flexDirection: 'column', width: '50%' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    fontSize: 15,
                                    fontWeight: 'bold',
                                    marginTop: '5vh',
                                    width: '100%',
                                    flexDirection: 'row',
                                }}
                            >
                                <div style={{ marginLeft: 5 }}>이름</div>
                                <div style={{ color: 'red', marginLeft: 5 }}>*</div>
                            </div>
                            <input
                                onChange={handleChange}
                                required
                                name="name"
                                style={{ width: '90%', height: 25, marginTop: 5 }}
                            />
                        </div>
                        <div style={{ flexDirection: 'column', width: '50%' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    fontSize: 15,
                                    fontWeight: 'bold',
                                    marginTop: '5vh',
                                    width: '50%',
                                    flexDirection: 'row',
                                }}
                            >
                                <div style={{ marginLeft: 5 }}>이메일</div>
                                <div style={{ color: 'red', marginLeft: 5 }}>*</div>
                            </div>
                            <input
                                type="email"
                                name="email"
                                required
                                onChange={handleChange}
                                style={{ width: '90%', height: 25, marginTop: 5 }}
                            />
                        </div>
                    </div>
                    <div style={{ width: '90%', flexDirection: 'row', display: 'flex' }}>
                        <div style={{ flexDirection: 'column', width: '50%' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    fontSize: 15,
                                    fontWeight: 'bold',
                                    marginTop: '5vh',
                                    width: '100%',
                                    flexDirection: 'row',
                                }}
                            >
                                <div style={{ marginLeft: 5 }}>연락처</div>
                            </div>
                            <input
                                onChange={handleChange}
                                name="phone"
                                type="tel"
                                style={{ width: '90%', height: 25, marginTop: 5 }}
                            />
                        </div>
                        <div style={{ flexDirection: 'column', width: '50%' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    fontSize: 15,
                                    fontWeight: 'bold',
                                    marginTop: '5vh',
                                    width: '50%',
                                    flexDirection: 'row',
                                }}
                            >
                                <div type="num" style={{ marginLeft: 5 }}>
                                    제작수량
                                </div>
                            </div>
                            <input
                                name="quantity"
                                onChange={handleChange}
                                style={{ width: '90%', height: 25, marginTop: 5 }}
                            />
                        </div>
                    </div>
                    <div style={{ width: '90%', flexDirection: 'row', display: 'flex' }}>
                        <div style={{ flexDirection: 'column', width: '100%' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    fontSize: 15,
                                    fontWeight: 'bold',
                                    marginTop: '5vh',
                                    width: '100%',
                                    flexDirection: 'row',
                                }}
                            >
                                <div style={{ marginLeft: 5 }}>요청사항</div>
                            </div>
                            <textarea
                                onChange={handleChange}
                                name="content"
                                style={{ width: '95%', height: '20vh', marginTop: 5 }}
                            />
                        </div>
                    </div>
                    <div style={{ width: '90%', flexDirection: 'column', display: 'flex' }}>
                        <div style={{ flexDirection: 'column' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    fontSize: 15,
                                    fontWeight: 'bold',
                                    marginTop: '7vh',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    border: '1px solid #ddd',
                                    padding: 10,
                                }}
                            >
                                <div style={{ marginLeft: 5 }}>
                                    <div>개인정보의 수집 및 이용목적 [수집하는 개인정보의 항목]</div>
                                    <div style={{ marginLeft: 10 }}>
                                        가. clabil(이하 회사)는 고객님의 문의사항 신청 시 아래와 같은 개인정보를
                                        수집하고 있습니다.
                                    </div>
                                    <div style={{ marginLeft: 20 }}>- 필수항목 : 이름, 연락처,</div>
                                    <div style={{ marginLeft: 30 }}>
                                        이메일, 주소, 화사명, 문의내용 [개인정보 수집 목적] 회사는 다음과 같은 이유로
                                        개인정보를 수집합니다.
                                    </div>
                                    <div style={{ marginLeft: 30 }}>목적 : 문의사항에 대한 답변 용도 </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    marginTop: 10,
                                    // alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    // border: '1px solid #ddd',
                                    display: 'flex',
                                }}
                            >
                                <input
                                    type="checkbox"
                                    checked={acceptPersonalData}
                                    onChange={(e) => {
                                        setAcceptPersonalData(!acceptPersonalData);
                                    }}
                                />
                                본인은 상기 개인정보수집, 이용 동의서 내용을 이해하고 동의합니다.
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    fontSize: 15,
                                    fontWeight: 'bold',
                                    marginTop: '5vh',
                                    width: '100%',
                                    flexDirection: 'row',
                                    // alignItems: 'center',
                                    justifyContent: 'center',
                                    // border: '1px solid #ddd',
                                }}
                            ></div>

                            <div
                                style={{
                                    marginTop: 30,
                                    justifyContent: 'center',
                                    width: '100%',
                                    display: 'flex',
                                }}
                            >
                                <ReCAPTCHA
                                    sitekey="6Lf9J5gpAAAAAHczveK-tc6jhvf7XbiJRTRj3Z4U"
                                    onChange={(value) => {
                                        console.log('Captcha value:', value);
                                        if (value.length > 100) {
                                            setCheckRobot(true);
                                        }
                                    }}
                                    style={{ height: '10vh' }}
                                />
                                <button
                                    type="submit"
                                    style={{
                                        justifyContent: 'center',
                                        border: '1px solid #ddd',
                                        display: 'flex',
                                        padding: 10,
                                        borderRadius: 10,
                                        backgroundColor: '#283952',
                                        color: 'white',
                                        width: '20%',
                                        fontSize: 25,
                                        fontWeight: 'bold',
                                        margin: 10,
                                        marginBottom: 100,
                                    }}
                                >
                                    보내기
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <FloatButton.Group shape="circle" style={{ right: 24 }}>
                <FloatButton.BackTop visibilityHeight={0} />
                <FloatButton
                    onClick={() => {
                        window.open(
                            'https://www.youtube.com/@user-tf9rk4mp5h/featured',
                            '_blank',
                            'noopener, noreferrer'
                        );
                    }}
                    icon={<YoutubeOutlined style={{ color: '#ff0000' }} />}
                />
                <FloatButton
                    type="primary"
                    onClick={() => {
                        navigate('/contect');
                    }}
                    icon={<QuestionCircleOutlined />}
                />
            </FloatButton.Group>
            <Footer />
        </div>
    );
}

export default Contact;
